import React from "react";

const Spinner = () => {
    return (
        <>
            <div className="vh-100 vw-100 d-flex justify-content-center align-items-center fw-bold fs-1">
                Loading . . .
            </div>
        </>
    )
}

export default Spinner;