import { LOAD_USER, LOAD_MEMBER_BY_TEAM_ID,LOAD_SELF_COOPERATION, ASSIGN_AGENCY_MEMBER_TO_TEAM, LOAD_MEMBER_WITHOUT_TEAM, LOAD_COOPERATION_BY_TEAM_ID, LOAD_COOPERATION_WITHOUT_TEAM, ASSIGN_COOPERATION_TO_TEAM, LOAD_MANAGER_WITHOUT_COOPERATION, ASSIGN_MANAGER_TO_COOPERATION, LOAD_MANAGER_BY_COOPERATION_ID, LOAD_SELF_COOPERATION_PERIOD, LOAD_COOPERATION_WITHOUT_PERIOD, LOAD_DOCUMENT_BY_PERIOD, DOWNLOAD_FILE, UPLOAD_FILE_COOPERATION, UPLOAD_FILE_TEMPLATE, DOWNLOAD_TEMPLATE, LOAD_ITEM_TAB, LOAD_ITEM_VALUE, LOAD_ITEM_VALUE_COOPERATION, LOAD_ITEM_VALUE_AGENCY, APPROVE_DINAS, APPROVE_PENILAIAN, LOAD_DOCUMENT_TYPE, APPROVE_DOC, APPROVE_KOPERASI, LOAD_COUNT, LOAD_REKAP_BY_PERIODE, LOAD_BS_DINAS, LOAD_BS_KOPERASI, LOAD_PHU_DINAS, LOAD_PHU_KOPERASI, LOAD_PREV_YEAR_BS_DINAS, LOAD_PREV_YEAR_BS_KOPERASI, LOAD_PREV_YEAR_PHU_DINAS, LOAD_PREV_YEAR_PHU_KOPERASI, LOAD_NOTIFICATION, LOAD_REKAP_COUNT } from "../actions/types";

const initialState = {
  user: null,
  member:null,
  selfCooperation: null,
  selfCooperationPeriod: null,
  agencyMemberNew: null,
  agencyMember:null,
  cooperationByTeam: null,
  cooperationWithoutTeam: null,
  cooperationWithoutPeriod: null,
  assignCooperation: null,
  managerWithoutCooperation: null,
  managerByCooperation: null,
  documentByPeriod: null,
  fileDownload: null,
  templateDownload: null,
  uploadFile: null,
  itemTab: null,
  itemValue: null,
  itemValueCooperation: null,
  prevYearItemValueCooperation: null,
  itemValueAgency: null,
  prevYearItemValueAgency: null,
  approve: null,
  documentType: null,
  count:null,
  rekapCount:null,
  rekap:null,
  bsDinas: null,
  bsKoperasi: null,
  phuDinas: null,
  phuKoperasi: null,
  prevYearBsDinas: null,
  prevYearBsKoperasi: null,
  prevYearPhuDinas: null,
  prevYearPhuKoperasi: null,
  notificationTotal: null,
};

export default function master(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case LOAD_USER:
            return {
                ...state,
                user: payload,
            };
        case LOAD_MEMBER_BY_TEAM_ID:
            return {
                ...state,
                member: payload.items,
            };
        case LOAD_SELF_COOPERATION:
            return{
                ...state,
                selfCooperation: payload[0],
            }
        case LOAD_SELF_COOPERATION_PERIOD:
            return{
                ...state,
                selfCooperationPeriod: payload.items,
            }
        case ASSIGN_AGENCY_MEMBER_TO_TEAM:
            return {
              ...state,
              agencyMemberNew: payload,
            };
        case LOAD_MEMBER_WITHOUT_TEAM:
            return{
                ...state,
                agencyMember: payload.items,
            }
        case LOAD_COOPERATION_BY_TEAM_ID:
            return{
                ...state,
                cooperationByTeam: payload.items,
            }
        case LOAD_COOPERATION_WITHOUT_TEAM:
            return{
                ...state,
                cooperationWithoutTeam: payload.items,
            }
        case ASSIGN_COOPERATION_TO_TEAM:
            return{
                ...state,
                assignCooperation: payload,
            }
        case LOAD_MANAGER_WITHOUT_COOPERATION:
            return{
                ...state,
                managerWithoutCooperation: payload.items
            }
        case ASSIGN_MANAGER_TO_COOPERATION:
            return{
                ...state,
            }
        case LOAD_MANAGER_BY_COOPERATION_ID:
            return{
                ...state,
                managerByCooperation: payload.items
            }
        case LOAD_COOPERATION_WITHOUT_PERIOD:
            return{
                ...state,
                cooperationWithoutPeriod: payload.items,
            }
        case LOAD_DOCUMENT_BY_PERIOD:
            return{
                ...state,
                documentByPeriod: payload,
            }
        case DOWNLOAD_FILE:
            return{
                ...state,
                fileDownload:payload,
            }
        case LOAD_DOCUMENT_TYPE:
            return{
                ...state,
                documentType:payload.items,
            }
        case DOWNLOAD_TEMPLATE:
            return{
                ...state,
                templateDownload:payload,
            }
        case UPLOAD_FILE_COOPERATION:
        case UPLOAD_FILE_TEMPLATE:
            return{
                ...state,
                uploadFile: payload,
            }
        case LOAD_ITEM_TAB:
            return{
                ...state,
                itemTab: payload
            }
        case LOAD_ITEM_VALUE:
            return{
                ...state,
                itemValue: payload,
            }
        case LOAD_ITEM_VALUE_COOPERATION:
            return{
                ...state,
                itemValueCooperation: payload.cur_year,
                prevYearItemValueCooperation: payload.prev_year,
            }
        case LOAD_ITEM_VALUE_AGENCY:
            return{
                ...state,
                itemValueAgency: payload.cur_year,
                prevYearItemValueAgency: payload.prev_year
            }
        case APPROVE_DINAS:
        case APPROVE_KOPERASI:
        case APPROVE_PENILAIAN:
        case APPROVE_DOC:
            return{
                ...state,
                approve:payload,
            }
        case LOAD_COUNT:
            return{
                ...state,
                count:payload,
            }
        case LOAD_REKAP_BY_PERIODE:
            return{
                ...state,
                rekap:payload,
            }
        case LOAD_BS_DINAS:
            return{
                ...state,
                bsDinas:payload,
            }
        case LOAD_BS_KOPERASI:
            return{
                ...state,
                bsKoperasi:payload,
            }
        case LOAD_PHU_DINAS:
            return{
                ...state,
                phuDinas:payload,
            }
        case LOAD_PHU_KOPERASI:
            return{
                ...state,
                phuKoperasi:payload,
            }
        case LOAD_PREV_YEAR_BS_DINAS:
            return{
                ...state,
                prevYearBsDinas:payload,
            }
        case LOAD_PREV_YEAR_BS_KOPERASI:
            return{
                ...state,
                prevYearBsKoperasi:payload,
            }
        case LOAD_PREV_YEAR_PHU_DINAS:
            return{
                ...state,
                prevYearPhuDinas:payload,
            }
        case LOAD_PREV_YEAR_PHU_KOPERASI:
            return{
                ...state,
                prevYearPhuKoperasi:payload,
            }
        case LOAD_NOTIFICATION:
            return{
                ...state,
                notificationTotal: payload.total
            }
        case LOAD_REKAP_COUNT:
            return{
                ...state,
                rekapCount: payload
            }
        default:
            return state;
    }
}
