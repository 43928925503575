import { combineReducers } from "@reduxjs/toolkit";
import auth from "./auth";
import data from "./data";
import master from "./master";

export default combineReducers({
  auth,
  data,
  master,
})
