import axios from "axios";

export const baseURL = process.env.REACT_APP_URL_API;

export const setAxios = () => {
    axios.defaults.baseURL = baseURL;
    axios.defaults.headers.common['Accept'] = '*/*';
    axios.defaults.headers.common['Content-Type'] = "application/x-www-form-urlencoded";
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = baseURL;
}

export const setToken = (token) => {
    if (token) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    } else {
      delete axios.defaults.headers.common['Authorization'];
    }
}

export const getCookie = (key) =>{
  var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
  return b ? b.pop() : "";
}

export const setCookie = (cookieName, value, days) =>{
  var expires = "";
  if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days*24*60*60*1000));
      expires = "; expires=" + date.toUTCString();
  }
  document.cookie = cookieName + "=" + (value || "")  + expires + "; path=/";
}

export const eraseCookie = (cookieName) => {   
  document.cookie = cookieName + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/';
}