//auth
export const USER_LOADED = "USER_LOADED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";
export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const RECOVERY_PASSWORD = "RECOVERY_PASSWORD";
export const CHANGE_PROFILE = "CHANGE_PROFILE";
export const AUTH_ERROR = "AUTH_ERROR";

//data
export const REFRESH_DATA = "REFRESH_DATA";
export const LOAD_DATA = "LOAD_DATA";
export const ADD_DATA = "ADD_DATA";
export const EDIT_DATA = "EDIT_DATA";
export const DELETE_DATA = "DELETE_DATA";
export const DELETE_DATA_BY_UUID = "DELETE_DATA_BY_UUID";
export const EXPORT_DATA = "EXPORT_DATA";
export const CRUD_FAIL = "CRUD_FAIL";

//master
export const LOAD_DOCUMENT_TYPE = "LOAD_DOCUMENT_TYPE";
export const DOWNLOAD_FILE = "DOWNLOAD_FILE";
export const DOWNLOAD_TEMPLATE = "DOWNLOAD_TEMPLATE";
export const UPLOAD_FILE_COOPERATION = "UPLOAD_FILE_COOPERATION";
export const UPLOAD_FILE_TEMPLATE = "UPLOAD_FILE_TEMPLATE";
export const LOAD_USER = "LOAD_USER";
export const LOAD_MEMBER_BY_TEAM_ID = "LOAD_MEMBER_BY_TEAM_ID";
export const LOAD_SELF_COOPERATION = "LOAD_SELF_COOPERATION";
export const LOAD_SELF_COOPERATION_PERIOD = "LOAD_SELF_COOPERATION_PERIOD";
export const LOAD_MEMBER_WITHOUT_TEAM = "LOAD_MEMBER_WITHOUT_TEAM";
export const ASSIGN_AGENCY_MEMBER_TO_TEAM = "ASSIGN_AGENCY_MEMBER_TO_TEAM";

export const LOAD_COOPERATION_BY_TEAM_ID = "LOAD_COOPERATION_BY_TEAM_ID";
export const LOAD_COOPERATION_WITHOUT_TEAM = "LOAD_COOPERATION_WITHOUT_TEAM";
export const LOAD_COOPERATION_WITHOUT_PERIOD = "LOAD_COOPERATION_WITHOUT_PERIOD";
export const ASSIGN_COOPERATION_TO_TEAM = "ASSIGN_COOPERATION_TO_TEAM";

export const LOAD_MANAGER_WITHOUT_COOPERATION = "LOAD_MANAGER_WITHOUT_COOPERATION";
export const ASSIGN_MANAGER_TO_COOPERATION = "ASSIGN_MANAGER_TO_COOPERATION";
export const LOAD_MANAGER_BY_COOPERATION_ID = "LOAD_MANAGER_BY_COOPERATION_ID";

export const LOAD_DOCUMENT_BY_PERIOD = "LOAD_DOCUMENT_BY_PERIOD";

export const LOAD_ITEM_TAB = "LOAD_ITEM_TAB";
export const LOAD_ITEM_VALUE = "LOAD_ITEM_VALUE";
export const LOAD_ITEM_VALUE_COOPERATION = "LOAD_ITEM_VALUE_COOPERATION";
export const LOAD_ITEM_VALUE_AGENCY = "LOAD_ITEM_VALUE_AGENCY";
export const APPROVE_DINAS = "APPROVE_DINAS";
export const APPROVE_KOPERASI = "APPROVE_KOPERASI";
export const APPROVE_PENILAIAN = "APPROVE_PENILAIAN";
export const APPROVE_DOC = "APPROVE_DOC";

export const LOAD_COUNT = "LOAD_COUNT";
export const LOAD_REKAP_COUNT = "LOAD_REKAP_COUNT";
export const LOAD_REKAP_BY_PERIODE = "LOAD_REKAP_BY_PERIODE";
export const LOAD_BS_DINAS = "LOAD_BS_DINAS";
export const LOAD_BS_KOPERASI = "LOAD_BS_KOPERASI";
export const LOAD_PHU_DINAS = "LOAD_PHU_DINAS";
export const LOAD_PHU_KOPERASI = "LOAD_PHU_KOPERASI";
export const LOAD_PREV_YEAR_BS_DINAS = "LOAD_PREV_YEAR_BS_DINAS";
export const LOAD_PREV_YEAR_BS_KOPERASI = "LOAD_PREV_YEAR_BS_KOPERASI";
export const LOAD_PREV_YEAR_PHU_DINAS = "LOAD_PREV_YEAR_PHU_DINAS";
export const LOAD_PREV_YEAR_PHU_KOPERASI = "LOAD_PREV_YEAR_PHU_KOPERASI";

export const LOAD_NOTIFICATION = "LOAD_NOTIFICATION";
