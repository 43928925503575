import { LOAD_DATA, REFRESH_DATA, ADD_DATA, EDIT_DATA, EXPORT_DATA } from "../actions/types";

const initialState = {
  list: null,
  data: null,
  export: null,
  total: 0,
  page: 0,
  loading: true,
  isEdit:false,
};

export default function data(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case REFRESH_DATA:
      return {
        ...state,
        list: payload.items,
        total: payload.total,
        page: payload.page,
        loading: false,
        data: null,
      };
    case EXPORT_DATA:
      return {
        ...state,
        export: payload.data,
        loading: false,
      };
    case LOAD_DATA:
    case ADD_DATA:
      return {
        ...state,
        data: payload,
        loading: false,
      };
    case EDIT_DATA:
      return {
        ...state,
        isEdit: payload
      }
    default:
      return state;
  }
}
